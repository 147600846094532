/** This template is used by /scripts/prebuild.js
 * to create ci stage specific environment
 */
export const environment = {
  production: true,
  hmr: false,
  TENANT_APP_PATH: '/df/',
  PUBLIC_APP_PATH: '/app/',
  API_URL: 'dailyfrenzy.io',
  CURRENCY: 'USD',
  CI_COMMIT_SHA: 'fd22cc4760e17e70f3ba2955a2d3384250d3dd44',
};
